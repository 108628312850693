/**
 * @file Auth Flow
 * @author Alwyn Tan
 */

import React, { useContext, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import Modal from 'components/simple/Modal'
import PhoneModalContent from 'components/compound/auth/PhoneModalContent'
import CodeModalContent from 'components/compound/auth/CodeModalContent'
import NameModalContent from 'components/compound/auth/NameModalContent'
import RootContext from 'RootContext'

// Auth is only rendered when:
// 1: There is no auth object
// 2: The user has no name
const Auth = () => {
  const [authPageIndex, setAuthPageIndex] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState('')
  const { auth, showAuth, setShowAuth } = useContext(RootContext)

  useEffect(() => {
    if (showAuth && auth.accessToken && auth.user?.name) setShowAuth(false)
  }, [showAuth, auth.accessToken, auth.user?.name, setShowAuth])

  const handlePhoneModalSubmit = number => {
    setPhoneNumber(number)
    setAuthPageIndex(authPageIndex + 1)
  }

  const handleCodeModalSubmit = () => {
    setAuthPageIndex(authPageIndex + 1)
  }

  const handleBack =
    authPageIndex === 1 ? () => setAuthPageIndex(authPageIndex - 1) : null

  const loginScreens = [
    <PhoneModalContent
      onSubmit={handlePhoneModalSubmit}
      defaultPhoneNumber={phoneNumber}
    />,
    <CodeModalContent
      onSubmit={handleCodeModalSubmit}
      onBack={() => setAuthPageIndex(authPageIndex - 1)}
      phoneNumber={phoneNumber}
    />,
  ]

  return (
    <AnimatePresence>
      {showAuth && (
        <Modal onBack={handleBack}>
          <AnimatePresence>
            {!auth?.accessToken ? (
              loginScreens[authPageIndex]
            ) : (
              <NameModalContent />
            )}
          </AnimatePresence>
        </Modal>
      )}
    </AnimatePresence>
  )
}

Auth.propTypes = {}

export default Auth
