/**
 * @file 404 page not found
 * @author Alwyn Tan
 */

import React from 'react'
import Page from 'components/templates/Page'

const FourOhFour = () => (
  <Page>
    <h3>Page not found :(</h3>
  </Page>
)

export default FourOhFour
