/**
 * @file A Party Screen
 * @author Alwyn Tan
 */

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Page from 'components/templates/Page'
import { share } from 'utils/window'
import { useHistory, useParams } from 'react-router-dom'
import useGetParty from 'hooks/query/party/useGetParty'
import { Share, MoreHorizontal } from 'react-feather'
import SEO from 'components/simple/SEO'
import LocationInfo from 'components/compound/party/details/LocationInfo'
import TimeInfo from 'components/compound/party/details/TimeInfo'
import ActionButtons from 'components/compound/party/details/ActionButtons'
import ModalController from 'components/compound/party/ModalController'
import OnboardingController from 'components/compound/onboarding/OnboardingController'
import PartyPageLoading from 'components/compound/loading/PartyPageLoading'
import { PARTY_MODAL } from 'constants/party'

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;
  pointer-events: ${({ cancelled }) => (cancelled ? 'none' : 'all')};
  opacity: ${({ cancelled }) => (cancelled ? 0.2 : 1)};
  overflow: ${({ cancelled }) => (cancelled ? 'hidden' : 'unset')};
`

const Title = styled.div`
  margin-bottom: 15px;
  position: relative;

  > h2 {
    padding: 0 20px;
    text-align: center;
  }

  > div {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

const Cover = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 225px;
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const InfoContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
  margin-bottom: 30px;
`

const Section = styled.section`
  margin-top: 30px;

  > *:first-child {
    padding-bottom: 10px;
  }
`

const SectionContent = styled.div`
  display: flex;
  align-items: center;
`

const PartyLink = styled.div`
  background-color: ${({ theme }) => theme.Tertiary};
  border-radius: 10px;
  padding: 10px 15px;
  flex: 1;
  overflow: hidden;

  > p {
    width: 100%;
    white-space: nowrap;
  }
`

const InvitePeople = styled.h4`
  color: ${({ theme }) => theme.Accent};
  padding-left: 15px;
  cursor: pointer;
`

const LeaderInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const LeaderPic = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-size: cover;
  background-image: url(${({ src }) => src});
  margin-right: 10px;
`

const CancelledText = styled.h2`
  position: absolute;
  top: calc(50% - 34px);
`

const ParticipantsContainer = styled.div``

const ParticipantsTitle = styled.div`
  display: flex;
`

const ViewAllParticipants = styled.h4`
  margin-left: auto;
  color: ${({ theme }) => theme.Accent};
  cursor: pointer;
`

const Participant = styled.div`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.Primary};
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-size: cover;
  background-image: url(${({ src }) => src});
  box-shadow: ${({ isLastWithMore }) =>
    isLastWithMore ? 'inset 0 0 0 2000px rgba(255, 255, 255, 0.5)' : ''};
  background-color: ${({ theme }) => theme.Primary};
  position: relative;
  text-align: center;
  cursor: pointer;

  :not(:first-child) {
    margin-left: -10px;
  }

  > * {
    display: block;
    height: 100%;
    margin: auto;
    text-align: center;
    color: #000000b3;
  }
`

const PartyBottomPadding = styled.div`
  min-height: calc(100px + env(safe-area-inset-top, 0px));
`

const Party = () => {
  const { id } = useParams()
  const history = useHistory()
  const ref = useRef(null)
  const [participantsToRender, setParticipantsToRender] = useState(0)

  const { isLoading, error, data } = useGetParty(id)
  const party = data?.party

  useEffect(() => {
    const handleResize = () => {
      // 50 for 1st element. 40 for left + right padding. Max for a max of 400px (total = how many to render after the first + the first)
      const renderCount =
        Math.floor((Math.min(window.innerWidth - 40, 400) - 50) / 40) + 1
      setParticipantsToRender(renderCount)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (error) {
    // todo: handle 500 vs 400 codes? right not everything defaults to not found
    history.push('404')
  }

  const handleShareClick = () => {
    share(`${window.location.origin}${window.location.pathname}`)
  }

  const handlePartyLeaderClick = () => {
    history.push(`/user/${party.host.id}`)
  }

  const handleViewAllParticipantsClick = () => {
    history.push({ state: { modal: PARTY_MODAL.PARTICIPANTS } })
  }

  // todo: handle when theres too many participants
  const renderParticipants = () => {
    // figure out how many to show (for only 1 row)
    if (party.participants && party.participants.length > 0) {
      const participants = []
      for (
        let i = 0;
        i < Math.min(participantsToRender, party.participants.length);
        i += 1
      ) {
        const isLastWithMore =
          i === participantsToRender - 1 &&
          participantsToRender < party.participants.length

        const handleParticipantClick = userID => {
          if (isLastWithMore) handleViewAllParticipantsClick()
          else history.push(`/user/${userID}`)
        }

        const p = party.participants[i]
        participants.push(
          <Participant
            key={p.id}
            src={p.picture}
            isLastWithMore={isLastWithMore}
            onClick={() => handleParticipantClick(p.id)}
          >
            {isLastWithMore && <MoreHorizontal />}
          </Participant>
        )
      }

      return participants
    }

    return <p>No one yet 🥺</p>
  }

  const isCancelled = party?.status === 'CANCELLED'

  if (isLoading || !party) return <PartyPageLoading />

  const partyPic =
    party?.picture || party?.location?.picture || party?.defaultPicture

  return (
    <Page>
      <SEO title={party?.title} image={partyPic} />
      <OnboardingController isHost={party.isHost} />
      <Container cancelled={isCancelled} ref={ref}>
        <Title>
          <h2>{party.title}</h2>
          <div>
            <Share size={22} onClick={handleShareClick} />
          </div>
        </Title>
        <Cover src={partyPic} />
        <InfoContainer>
          <TimeInfo
            isHost={party.isHost}
            date={party.startDate}
            isActive={party.status === 'ACTIVE'}
          />
          <LocationInfo
            isHost={party.isHost}
            location={party.location}
            isActive={party.status === 'ACTIVE'}
          />
        </InfoContainer>
        <Section>
          <h3>What's a Party Without People?</h3>
          <SectionContent
            onClick={handleShareClick}
            style={{ cursor: 'pointer' }}
          >
            <PartyLink>
              <p>
                {window.location.origin}
                {window.location.pathname}
              </p>
            </PartyLink>
            <InvitePeople>Invite People</InvitePeople>
          </SectionContent>
        </Section>
        <Section>
          <ParticipantsTitle>
            <h3>The Party ({party.participants.length})</h3>
            <ViewAllParticipants onClick={handleViewAllParticipantsClick}>
              View All
            </ViewAllParticipants>
          </ParticipantsTitle>
          <ParticipantsContainer>{renderParticipants()}</ParticipantsContainer>
        </Section>
        <Section>
          <h3>Party Leader</h3>
          <LeaderInfo onClick={handlePartyLeaderClick}>
            <LeaderPic src={party.host.picture} />
            <h3>{party.host.name}</h3>
          </LeaderInfo>
        </Section>
        <PartyBottomPadding />
        <ActionButtons
          id={party.id}
          isHost={party.isHost}
          isRegistered={party.isRegistered}
          status={party.status}
          participants={party.participants}
          location={party.location}
          time={party.startDate}
        />
      </Container>
      {isCancelled && <CancelledText>Party is Cancelled</CancelledText>}
      <ModalController id={id} />
    </Page>
  )
}

export default Party
