/**
 * @file Profile Page Loading Component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Page from 'components/templates/Page'
import PulseWrapper from './PulseWrapper'

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;
  padding-top: 35px;
`

const NameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    padding: 20px 0 40px 0;
  }
`

const ProfilePic = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: #e4e6ee;
`

const Name = styled.div`
  height: 34px;
  width: 120px;
  border-radius: 10px;
  background-color: #e4e6ee;
  margin: 20px 0px 40px 0px;
`

const InfoCard = styled.div`
  padding: 20px;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`

const InfoCardBackground = styled.div`
  position: absolute;
  z-index: -1;
  background-color: #e4e6ee;
  border-radius: 10px;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const InfoCardTitle = styled.div`
  width: 100px;
  max-width: 50%;
  height: 24px;
  background-color: #e4e6ee;
  border-radius: 10px;
  margin-bottom: 5px;
`

const InfoCardContent = styled.div`
  width: 300px;
  max-width: 90%;
  height: 22px;
  background-color: #e4e6ee;
  border-radius: 10px;
`

const ProfilePageLoading = () => {
  const renderInfoCards = num =>
    [...Array(num).keys()].map(key => (
      <InfoCard key={key}>
        <InfoCardBackground />
        <InfoCardTitle />
        <InfoCardContent />
      </InfoCard>
    ))

  return (
    <Page>
      <PulseWrapper>
        <Container>
          <NameContainer>
            <ProfilePic />
            <Name />
          </NameContainer>
          {renderInfoCards(3)}
        </Container>
      </PulseWrapper>
    </Page>
  )
}

export default ProfilePageLoading
