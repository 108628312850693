/**
 * @file Container to house the pulsing animation for the entire child
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PulseWrapper = ({ children }) => (
  <Container
    animate={{ opacity: 0.4 }}
    transition={{
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 0.8,
      ease: 'easeInOut',
    }}
  >
    {children}
  </Container>
)

PulseWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PulseWrapper
