/**
 * @file Party Started Modal when the host creates a party
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import Modal from 'components/simple/Modal'
import Button from 'components/simple/Button'
import confetti from 'canvas-confetti'
import { share } from 'utils/window'
import { useHistory } from 'react-router-dom'

const PartyEmoji = styled.span`
  display: inline-block;
  font-size: 120px;
  width: 100%;
  text-align: center;
  padding: 30px 0;
`

const StartedModal = () => {
  const history = useHistory()

  useEffect(() => {
    confetti({
      origin: {
        y: 0.85,
      },
    })
  }, [])

  const handleShareClick = () => {
    share(`${window.location.origin}${window.location.pathname}`)
  }

  const handleDoneClick = () => {
    history.replace(history.location.pathname)
  }

  return (
    <Modal>
      <h2 style={{ paddingBottom: 5 }}>You Started a Party!</h2>
      <p>Party time. Invite some people to come and get the party started!</p>
      <PartyEmoji>🎉</PartyEmoji>
      <Button style={{ marginBottom: 10 }} onClick={handleShareClick}>
        Invite Friends
      </Button>
      <Button type="secondary" onClick={handleDoneClick}>
        Done
      </Button>
    </Modal>
  )
}

export default StartedModal
