/**
 * @file Protected Route that requires authentication to access
 * @author Alwyn Tan
 */

import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import RootContext from 'RootContext'

const ProtectedRoute = ({ ...props }) => {
  const { auth, setShowAuth } = useContext(RootContext)

  useEffect(() => {
    if (!auth.accessToken) setShowAuth(true)
  }, [auth.accessToken, setShowAuth])

  return <Route {...props} />
}

ProtectedRoute.propTypes = {}

export default ProtectedRoute
