/**
 * @file Profile Info Cards about the user
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;

  > h3 {
    padding-bottom: 5px;
  }
`

const ProfileInfoCard = ({ title, content, style }) => {
  if (!content) return null

  return (
    <Container style={style}>
      <h3>{title}</h3>
      <p>{content}</p>
    </Container>
  )
}

ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

ProfileInfoCard.defaultProps = {
  content: '',
  style: {},
}

export default ProfileInfoCard
