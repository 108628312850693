/**
 * @file Modal that shows when user tries to join a closed party
 * @author Alwyn Tan
 */

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import RootContext from 'RootContext'
import Modal from 'components/simple/Modal'
import Button from 'components/simple/Button'
import useGetParty from 'hooks/query/party/useGetParty'
import { JOIN_PARTY_MESSAGE_TEMPLATE } from 'constants/party'

const JoinClosedPartyModal = ({ id }) => {
  const { auth, setShowAuth } = useContext(RootContext)
  const history = useHistory()
  const { data } = useGetParty(id)

  const party = data?.party

  const handleContactPartyLeaderClick = () => {
    window.open(
      `sms:${party.host.phoneNumber}&body=${JOIN_PARTY_MESSAGE_TEMPLATE(
        party.host.name,
        party.title
      )}`,
      '_self'
    )
  }

  const closeModal = () => {
    history.replace(history.location.pathname)
  }

  const handleAlreadyRegisteredClick = () => {
    history.push({ state: {} })
    setShowAuth(true)
  }

  if (!party) return null

  return (
    <Modal onCancel={closeModal}>
      <h2 style={{ paddingBottom: 5 }}>Oh No! The Party Already Started</h2>
      <p>Please contact the party leader if you’d like to join!</p>
      <Button
        style={{ margin: '30px 0 10px 0' }}
        onClick={handleContactPartyLeaderClick}
      >
        Contact Party Leader
      </Button>
      {!auth?.accessToken && (
        <Button type="secondary" onClick={handleAlreadyRegisteredClick}>
          I Already Registered
        </Button>
      )}
    </Modal>
  )
}

JoinClosedPartyModal.propTypes = {
  id: PropTypes.string.isRequired,
}

export default JoinClosedPartyModal
