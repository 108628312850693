/**
 * @file Header Row at the top of the page
 * @author Alwyn Tan
 */

import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Menu as MenuButton } from 'react-feather'
import RootContext from 'RootContext'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import HeaderDropdown from './HeaderDropdown'

const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: calc(20px + env(safe-area-inset-top, 0px));
  background-color: ${({ theme }) => theme.Primary};
`

const Background = styled(motion.div)`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.Primary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
`

const MaxWidthContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const ProfilePicture = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 25px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-size: cover;
  background-image: url(${({ src }) => src});
  cursor: pointer;
`

const Login = styled.h4`
  cursor: pointer;
`

const Header = () => {
  const { auth, setShowAuth, setShowMenu, hideHeader } = useContext(RootContext)
  const [showHeaderDropdown, setShowHeaderDropdown] = useState(false)
  const { scrollY } = useViewportScroll()
  const opacityAnim = useTransform(scrollY, [0, 60], [0, 1])

  if (hideHeader) return null

  return (
    <Container>
      <Background style={{ opacity: opacityAnim }} />
      <MaxWidthContainer>
        <MenuButton
          style={{ cursor: 'pointer' }}
          size={22}
          onClick={() => setShowMenu(true)}
        />
        {auth.user ? (
          <ProfilePicture
            src={auth?.user?.picture}
            onClick={() => setShowHeaderDropdown(true)}
          />
        ) : (
          <Login onClick={() => setShowAuth(true)}>sign in</Login>
        )}
        {showHeaderDropdown && (
          <HeaderDropdown onHide={() => setShowHeaderDropdown(false)} />
        )}
      </MaxWidthContainer>
    </Container>
  )
}

Header.propTypes = {}

export default Header
