/**
 * @file Start a Party
 * @author Alwyn Tan
 */

import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import RootContext from 'RootContext'
import Page from 'components/templates/Page'
import Input from 'components/simple/Input'
import Button from 'components/simple/Button'
import useCreateParty from 'hooks/query/party/useCreateParty'
import { PARTY_MODAL } from 'constants/party'

const Form = styled.form`
  width: 100%;
  height: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`

const SpaceFiller = styled.div`
  flex: 1;
`

const HelperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  opacity: 0.5;

  > * {
    padding-bottom: 10px;
  }
`

const CreateParty = () => {
  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
  })
  const { auth } = useContext(RootContext)
  const history = useHistory()
  const mutation = useCreateParty()

  useEffect(() => {
    if (auth?.user?.name) reset({ title: `${auth?.user?.name}'s Party` })
  }, [auth?.user?.name, reset])

  const onSubmit = async ({ title }) => {
    const { party } = await mutation.mutateAsync({ title })
    if (party) history.push(`/${party?.id}`, { modal: PARTY_MODAL.STARTED })
  }

  return (
    <Page>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Let’s Start a Party 🎉</h2>
        <p style={{ paddingTop: 10 }}>First things first. Name your party!</p>
        <Input
          style={{ marginTop: 30 }}
          control={control}
          title="Party Title"
          name="title"
          required
          defaultValue=""
        />
        <HelperContainer>
          <h4>Need some help? Here are some ideas:</h4>
          <p>Dinner @ Orenchi Ramen 🍜</p>
          <p>Elaine’s Housewarming Party 🏡🥳</p>
          <p>Saturday Dumpling Tour 🥟</p>
          <p>Last Min Drinks Tonight @ Nob Hill</p>
        </HelperContainer>
        <SpaceFiller />
        <Button disabled={!formState.isValid}>Finish</Button>
      </Form>
    </Page>
  )
}

CreateParty.propTypes = {}

export default CreateParty
