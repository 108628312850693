/**
 * @file Modal that renders all the participants
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/simple/Modal'
import { useHistory } from 'react-router-dom'
import useGetParty from 'hooks/query/party/useGetParty'
import { share } from 'utils/window'
import Button from 'components/simple/Button'

const Container = styled.div`
  height: 100%;
`

const Person = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  width: 100%;
`

const ProfileInfo = styled.div`
  min-width: 0;

  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.5;
  }
`

const ProfilePic = styled.div`
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  border-radius: 30px;
  margin-right: 15px;
  background-size: cover;
  background-image: url(${({ src }) => src});
  background-color: ${({ theme }) => theme.Primary};
`

const ParticipantsModal = ({ id }) => {
  const history = useHistory()

  const { data } = useGetParty(id)
  const party = data?.party

  if (!party) return null

  const handleCancel = () => {
    history.replace(history.location.pathname)
  }

  const handleInvite = () => {
    share(`${window.location.origin}${window.location.pathname}`)
  }

  const renderParticipants = () =>
    party.participants.map(p => (
      <Person key={p.id} onClick={() => history.push(`/user/${p.id}`)}>
        <ProfilePic src={p.picture} />
        <ProfileInfo style={{ minWidth: 0 }}>
          <h4>{p.name}</h4>
          <p>{p.funFact}</p>
        </ProfileInfo>
      </Person>
    ))

  return (
    <Modal onCancel={handleCancel}>
      <Container>
        <h2>Party People</h2>
        <h4 style={{ padding: '5px 0' }}>
          {party.participants.length > 0
            ? `Going (${party.participants.length})`
            : 'No One Yet 🥺'}
        </h4>
        {party.participants.length > 0 ? (
          renderParticipants()
        ) : (
          <Button style={{ marginTop: 10 }} onClick={handleInvite}>
            Invite Someone!
          </Button>
        )}
      </Container>
    </Modal>
  )
}

ParticipantsModal.propTypes = { id: PropTypes.string.isRequired }

export default ParticipantsModal
