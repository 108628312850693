/**
 * @file Update user mutation
 * @author Alwyn Tan
 */

import RootContext from 'RootContext'
import { serialize } from 'object-to-formdata'
import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

const UPDATE_USER_URL = '/api/user/update-user'

const postUpdateUser = async (userData, accessToken) =>
  fetch(UPDATE_USER_URL, {
    method: 'POST',
    body: serialize(userData),
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(response => response.json())

const useUpdateUser = () => {
  const { auth, setAuth } = useContext(RootContext)
  const queryClient = useQueryClient()

  return useMutation(async userData => {
    const toastID = toast.loading('Updating your profile')
    const { user } = await postUpdateUser(userData, auth.accessToken)

    if (user) {
      toast.success('Profile Updated', { id: toastID })
      setAuth({ ...auth, user })
      queryClient.invalidateQueries('auth-refresh')
      queryClient.invalidateQueries(['user', user.id])
    } else {
      toast.error('Error updating profile', { id: toastID })
    }

    return { user }
  })
}

export default useUpdateUser
