/**
 * @file Input Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'

const Container = styled.div`
  width: 100%;

  > h4 {
    padding-bottom: 5px;
  }
`

const StyledInput = styled.input`
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  outline: none;
  border: ${({ error, theme }) =>
    error ? `2px solid ${theme.Accent}` : 'none'};
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
`

const Error = styled.p`
  padding: 5px 0 0 10px;
  color: ${({ theme }) => theme.Accent};
`

const Input = ({
  control,
  name,
  error,
  defaultValue,
  required,
  validate,
  title,
  placeholder,
  style,
  type,
}) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required, validate },
  })

  return (
    <Container style={style}>
      {title && <h4>{title}</h4>}
      <StyledInput
        {...field}
        error={error}
        placeholder={placeholder}
        type={type}
      />
      {error && <Error>{error}</Error>}
    </Container>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.string,
  type: PropTypes.string,
}

Input.defaultProps = {
  defaultValue: '',
  required: false,
  validate: null,
  placeholder: 'Enter Something',
  title: '',
  style: {},
  error: '',
  type: 'text',
}

export default Input
