/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import RootContext from 'RootContext'
import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { post } from 'utils/api'

const LOGIN_URL = '/api/auth/login'

const postLogin = async ({ phoneNumber, code }) => {
  const { payload, user, error } = await post(
    LOGIN_URL,
    { phoneNumber, code },
    { includeCredentials: true }
  )

  if (error) {
    const e =
      error || 'Sorry, there appears to be a problem. Please try again later.'
    toast.error(e)
    throw new Error(e)
  }

  return { payload, user }
}

const useLogin = () => {
  const { setAuth } = useContext(RootContext)

  return useMutation(async ({ phoneNumber, code }) => {
    const { payload, user } = await postLogin({ phoneNumber, code })
    setAuth({ ...payload, user })

    return { payload, user }
  })
}

export default useLogin
