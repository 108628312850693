/**
 * @file Input Party Time Modal
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/simple/Modal'
import { useForm } from 'react-hook-form'
import Button from 'components/simple/Button'
import Input from 'components/simple/Input'
import dayjs from 'dayjs'
import * as chrono from 'chrono-node'
import useUpdateParty from 'hooks/query/party/useUpdateParty'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

const Preview = styled.p`
  padding: 5px 0 0 10px;
  opacity: 0.5;
`

const TimeModal = ({ id }) => {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
  })
  const mutation = useUpdateParty()
  const history = useHistory()

  const getPreviewTime = () => {
    const time = watch('time')
    if (time) {
      const parsedDate = chrono.parseDate(time)
      return dayjs(parsedDate).format('DD MMM YYYY, hh:mm a')
    }
    return ''
  }

  const previewTime = getPreviewTime()

  const closeModal = () => {
    history.replace(history.location.pathname)
  }

  const onSubmit = async ({ time }) => {
    const parsedDate = chrono.parseDate(time)
    const { party } = await mutation.mutateAsync({
      id,
      fields: { startDate: parsedDate },
    })
    if (party) {
      toast.success('Party Time Updated')
      closeModal()
    }
  }

  return (
    <Modal onCancel={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 style={{ paddingBottom: 5 }}>Choose a Time</h2>
        <p style={{ paddingBottom: 20 }}>Ready to decide on a time?</p>
        <Input
          control={control}
          name="time"
          placeholder="Eg. Today at 7pm"
          validate={value =>
            (value && dayjs(chrono.parseDate(value)).isAfter(dayjs())) ||
            'Time must be in the future'
          }
          error={formState.errors.time?.message}
          required
        />
        {previewTime && (
          <Preview>
            <i>Date: {previewTime}</i>
          </Preview>
        )}
        <Button
          style={{ marginTop: 30 }}
          disabled={!formState.isValid || mutation.isLoading}
        >
          Done
        </Button>
      </form>
    </Modal>
  )
}

TimeModal.propTypes = {
  id: PropTypes.string.isRequired,
}

export default TimeModal
