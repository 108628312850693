/**
 * @file Code Modal Content
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import Button from 'components/simple/Button'
import CodeInput from 'components/simple/CodeInput'
import useRequestLogin from 'hooks/query/auth/useRequestLogin'
import useLogin from 'hooks/query/auth/useLogin'
import { motion } from 'framer-motion'

const Form = styled(motion.form)``

const ResendCodeButton = styled.h4`
  color: ${({ theme }) => theme.Accent};
  width: 100%;
  text-align: center;
  padding: 30px 0;
  cursor: pointer;

  :active {
    opacity: 0.5;
  }
`

const PhoneNumber = styled.span`
  color: ${({ theme }) => theme.Accent};
  font-weight: 500;
  cursor: pointer;

  :active {
    opacity: 0.5;
  }
`

const CodeModalContent = ({ onSubmit: onSubmitProp, onBack, phoneNumber }) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' })
  const requestLoginMutation = useRequestLogin()
  const loginMutation = useLogin()

  const handleResendCode = async () => {
    requestLoginMutation.mutate(phoneNumber)
  }

  const onSubmit = ({ code }) => {
    loginMutation.mutate({ phoneNumber, code })
  }

  useEffect(() => {
    if (loginMutation.isSuccess) onSubmitProp(loginMutation.variables.code)
  }, [loginMutation.isSuccess, loginMutation.variables?.code, onSubmitProp])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2>SMS Code</h2>
      <p style={{ paddingTop: 5, paddingBottom: 30 }}>
        Please type in the code sent to{' '}
        <PhoneNumber onClick={onBack}>{phoneNumber}</PhoneNumber> so we know
        you’re a real person
      </p>
      <CodeInput
        control={control}
        name="code"
        required
        onSubmit={handleSubmit(onSubmit)}
      />
      <ResendCodeButton
        onClick={handleResendCode}
        disabled={requestLoginMutation.isLoading}
      >
        Code not received?
      </ResendCodeButton>
      <Button disabled={loginMutation.isLoading}>Next</Button>
    </Form>
  )
}

CodeModalContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
}

export default CodeModalContent
