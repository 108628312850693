/**
 * @file Home Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Button from 'components/simple/Button'
import Page from 'components/templates/Page'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
`

const Logo = styled.img`
  height: 120px;
`

const Home = () => {
  const history = useHistory()
  const handleCreateParty = () => history.push('/create')

  return (
    <Page hideHeader>
      <Container>
        <Logo src="/logo512.png" />
        <Button onClick={handleCreateParty}>Start a Party</Button>
      </Container>
    </Page>
  )
}

export default Home
