/**
 * @file Join a party
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const JOIN_PARTY_URL = '/api/party/join'

const postJoinParty = async (id, accessToken) =>
  post(JOIN_PARTY_URL, { id }, { accessToken })

const useJoinParty = () => {
  const { auth } = useContext(RootContext)
  const queryClient = useQueryClient()

  return useMutation(id => postJoinParty(id, auth.accessToken), {
    onSuccess: () => {
      queryClient.invalidateQueries('party')
    },
  })
}

export default useJoinParty
