/* eslint-disable import/prefer-default-export */

/**
 * @file Window related utilities
 * @author Alwyn Tan
 */

import toast from 'react-hot-toast'

/**
 * Shares a link through navigator if available, falls back to copying link to clipboard
 * @param {String} url URL to share
 * @param {String} body optional body to share alongside the link
 */
export const share = url => {
  if (navigator?.share) {
    navigator.share({ url })
  } else {
    navigator.clipboard.writeText(url)
    toast.success('Link Copied!')
  }
}
