/**
 * @file Action buttons for the party at the bottom of the screen
 * @author Alwyn Tan
 */

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/simple/Button'
import { useHistory } from 'react-router-dom'
import { PARTY_MODAL } from 'constants/party'
import RootContext from 'RootContext'
import useJoinParty from 'hooks/query/party/useJoinParty'
import { AUTH_SUCCESS_ACTION } from 'constants/auth'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(2px);
  padding: 20px 20px 30px 20px;
`

const HostButtons = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: space-around;

  > *:first-child {
    margin-right: 5px;
  }

  > *:last-child {
    margin-left: 5px;
  }
`

const PartyIsClosed = styled.div`
  color: ${({ theme }) => theme.Accent};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;

  > h4 {
    border: 2px solid ${({ theme }) => theme.Accent};
    text-align: center;
    padding: 8px 30px;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }
`

const UserButtons = styled.div`
  width: 100%;
  max-width: 400px;
`

const ActionButtons = ({ id, isHost, isRegistered, status }) => {
  const { auth, setShowAuth, authSuccessAction, setAuthSuccessAction } =
    useContext(RootContext)
  const history = useHistory()
  const joinPartyMutation = useJoinParty()

  const handleCancelPartyClick = () => {
    history.push({ state: { modal: PARTY_MODAL.CANCEL } })
  }

  const handleClosePartyClick = () => {
    history.push({ state: { modal: PARTY_MODAL.CLOSE } })
  }

  const handleJoinClosedPartyClick = () => {
    history.push({ state: { modal: PARTY_MODAL.JOIN_CLOSED } })
  }

  const handleJoinPartyClick = () => {
    if (!auth.accessToken) setShowAuth(true)
    setAuthSuccessAction(AUTH_SUCCESS_ACTION.JOIN_PARTY)
  }

  useEffect(() => {
    const checkJoin = async () => {
      if (
        auth.accessToken &&
        authSuccessAction === AUTH_SUCCESS_ACTION.JOIN_PARTY
      ) {
        setAuthSuccessAction(null)
        const { party } = await joinPartyMutation.mutateAsync(id)
        if (party) history.push({ state: { modal: PARTY_MODAL.JOINED } })
      }
    }

    checkJoin()
  }, [
    auth.accessToken,
    authSuccessAction,
    history,
    id,
    joinPartyMutation,
    setAuthSuccessAction,
  ])

  if (status === 'CANCELLED') return null

  const renderHostButtons = () => {
    if (status === 'CLOSED') return <Button disabled>Party Closed</Button>

    return (
      <>
        <Button type="secondary" onClick={handleCancelPartyClick}>
          Cancel It
        </Button>
        <Button onClick={handleClosePartyClick}>Close It</Button>
      </>
    )
  }

  const renderUserButtons = () => {
    if (isRegistered) {
      return (
        <Button style={{ backgroundColor: '#52BB8B' }} disabled>
          Yes I'll be there!
        </Button>
      )
    }

    if (status === 'CLOSED') {
      return (
        <PartyIsClosed>
          <h4>🛑 The party is closed</h4>
          <Button onClick={handleJoinClosedPartyClick}>
            I’d still like to go
          </Button>
        </PartyIsClosed>
      )
    }

    return <Button onClick={handleJoinPartyClick}>I'm In!</Button>
  }

  return (
    <Container>
      {isHost ? (
        <HostButtons>{renderHostButtons()}</HostButtons>
      ) : (
        <UserButtons>{renderUserButtons()}</UserButtons>
      )}
    </Container>
  )
}

ActionButtons.propTypes = {
  id: PropTypes.string.isRequired,
  isHost: PropTypes.bool.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
}

export default ActionButtons
