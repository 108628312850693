/**
 * @file Hook to retrieve all joined parties
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_JOINED_PARTIES_URL = '/api/party/joined'

const getJoinedParties = async (lastLoadedID, accessToken) => {
  const suffix = lastLoadedID
    ? `?lastLoadedID=${encodeURIComponent(lastLoadedID)}`
    : ''
  const { parties, canLoadMore } = await get(
    `${GET_JOINED_PARTIES_URL}${suffix}`,
    { accessToken }
  )

  return { data: parties, canLoadMore }
}

const useJoinedParties = () => {
  const { auth } = useContext(RootContext)
  return useInfiniteQuery(
    'party-joined',
    async ({ pageParam }) => getJoinedParties(pageParam, auth.accessToken),
    {
      getNextPageParam: lastPage => {
        if (lastPage.canLoadMore)
          return lastPage.data[lastPage.data.length - 1].id
        return undefined
      },
      refetchOnWindowFocus: false,
    }
  )
}

export default useJoinedParties
