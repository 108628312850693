/**
 * @file Name Modal to collect user's name
 * @author Alwyn Tan
 */

import React from 'react'
import { useForm } from 'react-hook-form'
import Button from 'components/simple/Button'
import Input from 'components/simple/Input'
import useUpdateUser from 'hooks/query/useUpdateUser'

const NameModalContent = () => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })
  const mutation = useUpdateUser()

  const onSubmit = ({ name }) => {
    mutation.mutate({ name })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 style={{ paddingBottom: 5 }}>What's Your Name?</h2>
      <p style={{ paddingBottom: 20 }}>Just so people know who you are</p>
      <Input
        control={control}
        name="name"
        required
        placeholder="Johnny Appleseed"
      />
      <Button style={{ marginTop: 30 }} disabled={!formState.isValid}>
        Next
      </Button>
    </form>
  )
}

export default NameModalContent
