/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import dayjs from 'dayjs'
import { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const REFRESH_TOKEN_URL = '/api/auth/refresh-token'
const SILENT_LOGIN_QUERY_KEY = 'auth-refresh'

const getSilentLogin = async () =>
  get(REFRESH_TOKEN_URL, { includeCredentials: true })

const useSilentLogin = () => {
  const { setAuth, setShowAuth } = useContext(RootContext)
  const queryClient = useQueryClient()

  return useQuery(
    SILENT_LOGIN_QUERY_KEY,
    async () => {
      const result = await getSilentLogin()

      if (result) {
        const { payload, user } = result
        setAuth({ ...payload, user })
        setShowAuth(false)

        // identifies the user
        window.analytics.identify(user.id, user)

        // todo: test the expiry part out
        if (payload?.expiry)
          setTimeout(
            () => queryClient.invalidateQueries(SILENT_LOGIN_QUERY_KEY),
            payload?.expiry
          )
      }

      return result
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: dayjs
        .duration({ minutes: 15 })
        .asMilliseconds(),
    }
  )
}

export default useSilentLogin
