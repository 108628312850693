/**
 * @file Edit Profile Modal
 * @author Alwyn Tan
 */

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/simple/Modal'
import Button from 'components/simple/Button'
import Input from 'components/simple/Input'
import { useForm } from 'react-hook-form'
import RootContext from 'RootContext'
import useUpdateUser from 'hooks/query/useUpdateUser'
import ImageInput from 'components/simple/ImageInput'

const EditGroup = styled.div`
  padding-bottom: 40px;

  h3 {
    padding: 15px 0 10px 0;
  }
`

const EditProfileModal = ({ onCancel }) => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })
  const { auth } = useContext(RootContext)
  const user = auth?.user
  const mutation = useUpdateUser()

  useEffect(() => {
    if (mutation.isSuccess) onCancel()
  }, [mutation?.isSuccess, onCancel])

  const onSubmit = ({ picture, name, profession, funFact }) => {
    mutation.mutate({ picture, name, profession, funFact })
  }

  if (!user) return null

  return (
    <Modal onCancel={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditGroup>
          <h2>Edit Personal Info</h2>
          <h3>Profile Picture</h3>
          <ImageInput
            control={control}
            name="picture"
            defaultValue={user.picture}
            resizeHeight={600}
            resizeWidth={600}
            previewHeight={100}
            previewWidth={100}
          />
          <h3>Name</h3>
          <Input
            control={control}
            name="name"
            placeholder="Jane Appleseed"
            defaultValue={user.name}
          />
          <h3>Profession</h3>
          <Input
            control={control}
            name="profession"
            placeholder="Digital Marketer @ Solo"
            defaultValue={user.profession}
          />
          <h3>Fun Fact</h3>
          <Input
            control={control}
            name="funFact"
            placeholder="I have been to all 7 continents"
            defaultValue={user.funFact}
          />
        </EditGroup>
        <Button
          disabled={
            !formState.isDirty || formState.isvalid || mutation.isLoading
          }
        >
          Save
        </Button>
      </form>
    </Modal>
  )
}

EditProfileModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default EditProfileModal
