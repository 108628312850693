/**
 * @file Party Page Loading Component
 * @author Alwyn Tan
 */

import Page from 'components/templates/Page'
import React from 'react'
import styled from 'styled-components'
import PulseWrapper from './PulseWrapper'

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  border-radius: 10px;
  height: 34px;
  width: 80%;
  align-self: center;
  margin-bottom: 15px;
  background-color: #e4e6ee;
`

const Cover = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 225px;
  background-color: green;
  background-color: #e4e6ee;
`

const InfoContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;

  > div {
    border-radius: 10px;
    height: 120px;
    width: 100%;
    background-color: #e4e6ee;
  }

  > div:first-child {
    margin-right: 5px;
  }

  > div:last-child {
    margin-left: 5px;
  }
`

const SectionTitle = styled.div`
  margin-top: 30px;
  height: 24px;
  margin-bottom: 10px;
  width: 50%;
  background-color: #e4e6ee;
  border-radius: 10px;
`

const ShareBox = styled.div`
  height: 42px;
  width: 100%;
  display: flex;

  > div {
    background-color: #e4e6ee;
    border-radius: 10px;
  }

  > div:first-child {
    width: 75%;
  }

  > div:last-child {
    margin-left: 15px;
    width: 25%;
  }
`

const Participant = styled.div`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.Primary};
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #e4e6ee;
  position: relative;

  :not(:first-child) {
    margin-left: -10px;
  }
`

const HostContainer = styled.div`
  display: flex;
`

const HostName = styled.div`
  margin-left: 10px;
  display: inline-block;
  align-self: center;
  justify-self: center;
  border-radius: 10px;
  height: 24px;
  width: 100px;
  background-color: #e4e6ee;
`

const PartyLoading = () => (
  <Page>
    <PulseWrapper>
      <Container>
        <Title />
        <Cover />
        <InfoContainer>
          <div />
          <div />
        </InfoContainer>
        <SectionTitle />
        <ShareBox>
          <div />
          <div />
        </ShareBox>
        <SectionTitle />
        <div>
          <Participant />
          <Participant />
          <Participant />
          <Participant />
        </div>
        <SectionTitle />
        <HostContainer>
          <Participant />
          <HostName />
        </HostContainer>
      </Container>
    </PulseWrapper>
  </Page>
)

export default PartyLoading
