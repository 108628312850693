/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Page from 'components/templates/Page'
import SEO from 'components/simple/SEO'
import PartyCardLoading from 'components/compound/loading/PartyCardLoading'
import useJoinedParties from 'hooks/query/party/useJoinedParties'
import InfiniteScroll from 'components/simple/InfiniteScroll'
import PartyCard from 'components/compound/party/PartyCard'

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;
  text-align: center;
`

const Title = styled.h2`
  padding-bottom: 10px;
`

const YourParties = () => (
  <Page>
    <SEO title="Your Parties" />
    <Container>
      <Title>Your Parties</Title>
      <InfiniteScroll
        infiniteQueryHook={useJoinedParties}
        loadingComponent={<PartyCardLoading />}
      >
        {item => <PartyCard id={item.id} key={item.id} />}
      </InfiniteScroll>
      <div style={{ minHeight: 60 }} />
    </Container>
  </Page>
)

export default YourParties
