/**
 * @file Root of the app, initializers, and context providers
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import duration from 'dayjs/plugin/duration'
import RootContext from 'RootContext'
import GoogleService from 'services/google'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './index.css'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(duration)

const theme = {
  Accent: '#DD3F4E',
  Primary: '#F7F7FA',
  Secondary: '#FFFFFF',
  Tertiary: '#F0F1F5',
  Text: '#000000',
  Success: '#52BB8B',
}

const queryClient = new QueryClient()

const Root = () => {
  const [servicesLoaded, setServicesLoaded] = useState(false)
  const [auth, setAuth] = useState({})
  const [authSuccessAction, setAuthSuccessAction] = useState(null)
  const [showAuth, setShowAuth] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)

  useEffect(() => {
    // init services
    const init = async () => {
      await GoogleService.initialize()
      setServicesLoaded(true)
    }

    init()
  }, [])

  if (!servicesLoaded) return null

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <RootContext.Provider
              value={{
                auth,
                setAuth,
                authSuccessAction,
                setAuthSuccessAction,
                showAuth,
                setShowAuth,
                showMenu,
                setShowMenu,
                hideHeader,
                setHideHeader,
              }}
            >
              <App />
            </RootContext.Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
      <Toaster
        containerStyle={{ top: 'max(env(safe-area-inset-top, 20px), 20px)' }}
        reverseOrder
        toastOptions={{
          style: {
            borderRadius: 200,
            backgroundColor: theme.Secondary,
            color: theme.Text,
          },
        }}
      />
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
