/**
 * @file Cancel Party Onboarding Overlay
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Button from 'components/simple/Button'

const Container = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Info = styled.div`
  background-color: white;
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 300px;
  align-self: flex-start;

  > h3 {
    padding-bottom: 10px;
  }
`

const HostButtons = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: space-around;

  > *:first-child {
    margin-right: 5px;
  }

  > *:last-child {
    margin-left: 5px;
  }
`

const CancelPartyOverlay = () => (
  <Container>
    <Info>
      <h3>Cancel The Party</h3>
      <p>If you change your mind and want to cancel the party, click here.</p>
    </Info>
    <HostButtons>
      <Button type="secondary">Cancel It</Button>
      <div style={{ width: '100%' }} />
    </HostButtons>
  </Container>
)

export default CancelPartyOverlay
