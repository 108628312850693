/**
 * @file Image Input
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Compressor from 'compressorjs'
import { useController } from 'react-hook-form'
import { Camera } from 'react-feather'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  input[type='file']::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.Secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 15px 0;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`

const ImageInput = ({
  name,
  control,
  defaultValue,
  required,
  style,
  resizeWidth,
  resizeHeight,
  previewWidth,
  previewHeight,
}) => {
  const [imagePreview, setImagePreview] = useState('')
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required },
  })

  const handleImageUpload = e => {
    if (e.target.files.length > 0) {
      // always compress and resize
      new Compressor(e.target.files[0], {
        width: resizeWidth,
        height: resizeHeight,
        success(result) {
          field.onChange(result)
        },
        error() {
          // todo: handle error in the future
        },
      })
    }
  }

  useEffect(() => {
    let objectURL

    if (typeof field.value === 'object') {
      objectURL = URL.createObjectURL(field.value)
      setImagePreview(objectURL)
    } else if (typeof field.value === 'string' && field.value !== '') {
      objectURL = new URL(field.value)
      setImagePreview(objectURL)
    }

    return () => URL.revokeObjectURL(objectURL)
  }, [field.value])

  return (
    <Container style={style}>
      <ImageContainer
        width={previewWidth}
        height={previewHeight}
        style={imagePreview ? { backgroundImage: `url(${imagePreview})` } : {}}
      >
        {!imagePreview && <Camera style={{ opacity: 0.7 }} />}
      </ImageContainer>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
    </Container>
  )
}

ImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  resizeHeight: PropTypes.number.isRequired,
  resizeWidth: PropTypes.number.isRequired,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
}

ImageInput.defaultProps = {
  style: {},
  defaultValue: '',
  required: false,
  previewWidth: 100,
  previewHeight: 100,
}

export default ImageInput
