/**
 * @file Party details constants
 * @author Alwyn Tan
 */

export const PARTY_MODAL = {
  STARTED: 'PARTY_STARTED',
  JOINED: 'PARTY_JOINED',
  CANCEL: 'PARTY_CANCEL',
  CLOSE: 'PARTY_CLOSE',
  TIME: 'PARTY_TIME',
  LOCATION: 'PARTY_LOCATION',
  JOIN_CLOSED: 'PARTY_JOIN_CLOSED',
  PARTICIPANTS: 'PARTY_PARTICIPANTS',
}

export const PARTY_CLOSE_MESSAGE = 'Hey everyone 👋🏼 See you soon!'

export const JOIN_PARTY_MESSAGE_TEMPLATE = (hostName, eventTitle) =>
  `Hi ${hostName}, I'd love to join ${eventTitle} if there's still space!`
