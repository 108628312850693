/**
 * @file Controls the onboarding pages
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import ClosePartyOverlay from './host/ClosePartyOverlay'
import CancelPartyOverlay from './host/CancelPartyOverlay'
import TimeOverlay from './host/TimeOverlay'
import LocationOverlay from './host/LocationOverlay'

const Background = styled(motion.div)`
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 30px 20px;
`

const MaxWidthContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 400px;
`

const OnboardingController = ({ isHost }) => {
  const [hostOnboarded, setHostOnboarded] = useState(() =>
    localStorage.getItem('onboarded-host')
  )
  const [currScreen, setCurrScreen] = useState(0)

  const hostScreens = [
    <ClosePartyOverlay />,
    <CancelPartyOverlay />,
    <TimeOverlay />,
    <LocationOverlay />,
  ]

  const handleScreenClick = () => {
    if (isHost) {
      if (currScreen < hostScreens.length - 1) {
        setCurrScreen(currScreen + 1)
      } else {
        localStorage.setItem('onboarded-host', true)
        setHostOnboarded(true)
      }
    }
  }

  return (
    <AnimatePresence>
      {isHost && !hostOnboarded && (
        <Background
          initial={{
            backdropFilter: 'blur(0px) brightness(100%)',
            WebkitBackdropFilter: 'blur(0px) brightness(100%)',
          }}
          animate={{
            backdropFilter: 'blur(3px) brightness(50%)',
            WebkitBackdropFilter: 'blur(3px) brightness(50%)',
          }}
          exit={{
            backdropFilter: 'blur(0px) brightness(100%)',
            WebkitBackdropFilter: 'blur(0px) brightness(100%)',
          }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          exitTransition={{ ease: 'easeOut' }}
          onClick={handleScreenClick}
        >
          <MaxWidthContainer>{hostScreens[currScreen]}</MaxWidthContainer>
        </Background>
      )}
    </AnimatePresence>
  )
}

OnboardingController.propTypes = {
  isHost: PropTypes.bool,
}

OnboardingController.defaultProps = {
  isHost: false,
}

export default OnboardingController
