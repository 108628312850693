/**
 * @file Location input that uses google maps
 * @author Alwyn Tan
 */

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import GoogleService from 'services/google'

const Container = styled.div``

const StyledSelect = styled(AsyncSelect)`
  * {
    color: ${({ theme }) => theme.Text} !important;
  }

  .react-select__placeholder {
    opacity: 0.2;
  }

  .react-select__control {
    height: 50px;
    background-color: ${({ theme }) => theme.Secondary};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border: 0;
    border-radius: 10px;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    white-space: nowrap;
  }

  .react-select__value-container {
    height: 100%;
    padding-left: 12px;
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__input {
    height: 100%;

    > input {
      margin-bottom: 0;
    }
  }

  .react-select__menu {
    margin-top: 5;
    background-color: ${({ theme }) => theme.Secondary};

    .react-select__option--is-focused,
    .react-select__option:focus,
    .react-select__option:hover,
    .react-select__option:active {
      background-color: ${({ theme }) => theme.Secondary};
    }
  }
`

const MapPreview = styled.div`
  height: 250px;
  border-radius: 10px;
  margin-top: 10px;
  opacity: ${({ hasValue }) => (hasValue ? 1 : 0.5)};
`

const LocationInput = ({
  style,
  name,
  control,
  defaultValue,
  required,
  validate,
  placeholder,
}) => {
  const [sessionToken, setSessionToken] = useState(null)
  const [map, setMap] = useState(null)
  const mapMarkersRef = useRef([])
  const mapComponentRef = useRef(null) // ref of the component
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required, validate },
  })

  const loadOptions = async (input, callback) => {
    if (!sessionToken)
      setSessionToken(GoogleService.getAutocompleteSessionToken())
    const response = await GoogleService.getPlacePredictions({
      input,
      sessionToken,
    })
    const options = response.predictions.map(prediction => ({
      value: prediction,
      label: prediction.description,
    }))

    callback(options)
  }

  useEffect(() => {
    const loadMap = async () => {
      setMap(
        await GoogleService.initializeMap(mapComponentRef.current, {
          center: { lat: 37.7854, lng: -122.4294 },
          zoom: 12,
          disableDefaultUI: true,
        })
      )
    }

    loadMap()
  }, [])

  const transform = {
    output: async value => {
      const place = await GoogleService.getPlaceDetails(map, {
        placeId: value.place_id,
        sessionToken,
      })
      setSessionToken(null)

      for (const marker of mapMarkersRef.current) marker.setMap(null)
      mapMarkersRef.current = []
      if (place.geometry) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport)
        } else {
          map.setCenter(place.geometry.location)
          map.setZoom(14)
        }

        mapMarkersRef.current.push(
          GoogleService.createMarker({ map, position: place.geometry.location })
        )
      }

      const address = GoogleService.parseAddressComponents(
        place.address_components
      )

      const picture =
        place?.photos &&
        place.photos[0].getUrl({ maxHeight: 225, maxWidth: 335 })

      return { name: place.name, ...address, placeID: place.place_id, picture }
    },
  }

  return (
    <Container style={style}>
      {map && (
        <StyledSelect
          cacheOptions
          classNamePrefix="react-select"
          loadOptions={loadOptions}
          placeholder={placeholder}
          {...field}
          onChange={async v => field.onChange(await transform.output(v.value))}
          value={
            field.value ? { value: field.value, label: field.value?.name } : ''
          }
        />
      )}
      <MapPreview ref={mapComponentRef} hasValue={field.value} />
    </Container>
  )
}

LocationInput.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
}

LocationInput.defaultProps = {
  style: {},
  defaultValue: '',
  required: false,
  validate: null,
  placeholder: 'Enter a Location',
}

export default LocationInput
