/**
 * @file Modal Content that asks for phone number (Auth)
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import PhoneInput from 'components/simple/PhoneInput'
import Button from 'components/simple/Button'
import useRequestLogin from 'hooks/query/auth/useRequestLogin'

const Form = styled(motion.form)``

const PhoneModalContent = ({ onSubmit: onSubmitProp, defaultPhoneNumber }) => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })

  const mutation = useRequestLogin()

  const onSubmit = async ({ phoneNumber: formPhoneNumber }) => {
    mutation.mutate(formPhoneNumber)
  }

  useEffect(() => {
    if (mutation.isSuccess) onSubmitProp(mutation.variables)
  }, [mutation.isSuccess, mutation.variables, onSubmitProp])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 style={{ paddingBottom: 5 }}>What's Your Number?</h2>
      <p style={{ paddingBottom: 20 }}>
        Let's make you a quick account or log you in!
      </p>
      <PhoneInput
        required
        control={control}
        name="phoneNumber"
        defaultValue={defaultPhoneNumber}
        validate={value => isMobilePhone(value) || 'Invalid Phone Number'}
      />
      <h6 style={{ paddingTop: 10 }}>
        * We currently only support US phone numbers
      </h6>
      <Button
        style={{ marginTop: 30 }}
        disabled={!formState.isValid || mutation.isLoading}
      >
        Next
      </Button>
    </Form>
  )
}

PhoneModalContent.propTypes = {
  defaultPhoneNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

PhoneModalContent.defaultProps = {
  defaultPhoneNumber: '',
}

export default PhoneModalContent
