/**
 * @file Page Template
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Header from 'components/compound/Header'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ChildContainer = styled(Container)`
  padding: 0 20px;
  padding-top: ${({ hideHeader }) => (hideHeader ? 0 : 65)}px;
`

const Page = ({ children, hideHeader }) => (
  <Container>
    {!hideHeader && <Header />}
    <ChildContainer
      hideHeader={hideHeader}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.25 }}
    >
      {children}
    </ChildContainer>
  </Container>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
}

Page.defaultProps = { hideHeader: false }

export default Page
