/**
 * @file Update a Party Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const UPDATE_PARTY_URL = '/api/party/update'

const postUpdateParty = async (id, fields, accessToken) =>
  post(UPDATE_PARTY_URL, { id, fields }, { accessToken })

const useUpdateParty = () => {
  const { auth } = useContext(RootContext)
  const queryClient = useQueryClient()

  // defaults party host to the auth, unless specified
  return useMutation(
    ({ id, fields }) => postUpdateParty(id, fields, auth.accessToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('party')
      },
    }
  )
}

export default useUpdateParty
