/**
 * @file Location Info
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Edit2 } from 'react-feather'
import { PARTY_MODAL } from 'constants/party'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  background-color: #01021d;
  height: 120px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  cursor: pointer; // todo: if user is not host?
  position: relative;

  :first-child {
    margin-right: 5px;
  }

  :last-child {
    margin-left: 5px;
  }

  > h5 {
    opacity: 0.5;
    color: white;
  }

  > div {
    color: white;
    height: 60px;
    display: flex;
    align-items: center;

    > h3 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`

const EditButton = styled(Edit2)`
  top: 12px;
  right: 12px;
  position: absolute;
`

const LocationInfo = ({ isOnboarding, isActive, isHost, location }) => {
  const history = useHistory()

  const handleClick = () => {
    if (isHost && isActive && !isOnboarding)
      history.push({ state: { modal: PARTY_MODAL.LOCATION } })
  }

  return (
    <Container onClick={handleClick}>
      {isHost && isActive && <EditButton color="white" size={15} />}
      <h5>Where</h5>
      <div>
        <h3>{location.name || 'TBD'}</h3>
      </div>
    </Container>
  )
}

LocationInfo.propTypes = {
  isOnboarding: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isHost: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }),
}

LocationInfo.defaultProps = {
  isOnboarding: false,
  location: {
    name: '',
  },
}

export default LocationInfo
