/**
 * @file Party Card for each event
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useGetParty from 'hooks/query/party/useGetParty'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

const Container = styled.div`
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 225px;
  background: ${({ showGradient, src }) =>
    `${
      showGradient
        ? 'linear-gradient(180deg,rgba(0, 0, 0, 0.195) 0%,rgba(0, 0, 0, 0.65) 100%),'
        : ''
    }url(${src})`};
  background-size: cover;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  padding: 10px;
`

const HostInfo = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  display: flex;
  align-items: center;
`

const HostPicture = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-size: cover;
  background-image: url(${({ src }) => src});
  margin-right: 5px;
`

const PartyCard = ({ id }) => {
  const history = useHistory()
  const { data } = useGetParty(id)
  const party = data?.party

  const handleClick = () => {
    history.push(id)
  }

  const getFormattedStartDate = () => {
    if (!party?.startDate) return null
    const d = dayjs(party.startDate)

    const formattedTime = d.format('hh:mma')

    let formattedDay = ''
    if (d.isToday()) formattedDay = 'Today'
    else if (d.isTomorrow()) formattedDay = 'Tomorrow'
    else formattedDay = d.format('MMM DD')

    return `${formattedDay}, ${formattedTime}`
  }

  if (!party) return null

  return (
    <Container
      src={party?.picture || party?.location?.picture || party.defaultPicture}
      onClick={handleClick}
      showGradient={party?.picture || party?.location?.picture}
    >
      <h2 style={{ paddingBottom: 5 }}>{party.title}</h2>
      <h3>{getFormattedStartDate() || 'TBD'}</h3>
      <HostInfo>
        <HostPicture src={party.host.picture} />
        <h4>{party.host.name}</h4>
      </HostInfo>
    </Container>
  )
}

PartyCard.propTypes = { id: PropTypes.string.isRequired }

export default PartyCard
