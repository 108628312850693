/**
 * @file Create a Party Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const CREATE_PARTY_URL = '/api/party/create'

const postCreateParty = async (fields, accessToken) =>
  post(CREATE_PARTY_URL, fields, { accessToken })

const useCreateParty = () => {
  const { auth } = useContext(RootContext)

  // defaults party host to the auth, unless specified
  return useMutation(fields =>
    postCreateParty({ host: auth.user.id, ...fields }, auth.accessToken)
  )
}

export default useCreateParty
