/**
 * @file SEO wrapper
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const SEO = ({ description, lang, meta, title, image }) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={title}
    meta={[
      { name: `description`, content: description },
      { property: `og:title`, content: title },
      { property: `og:description`, content: description },
      { property: `og:image`, content: image },
      { property: `og:image:secure`, content: image },
      { property: `og:type`, content: `website` },
      { name: `twitter:card`, content: `summary` },
      { name: `twitter:creator`, content: 'Solo' },
      { name: `twitter:title`, content: title },
      { name: `twitter:description`, content: description },
    ].concat(meta)}
  />
)

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: 'Solo Party! Create and Find Live Parties/Groups Near You',
  image: '',
}

export default SEO
