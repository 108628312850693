/**
 * @file Party Location Input Modal
 * @author Alwyn Tan
 */

// todo: integrate with google maps

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Modal from 'components/simple/Modal'
import { useForm } from 'react-hook-form'
import Button from 'components/simple/Button'
import useUpdateParty from 'hooks/query/party/useUpdateParty'
import toast from 'react-hot-toast'
import LocationInput from 'components/simple/LocationInput'

const LocationModal = ({ id }) => {
  const { control, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const mutation = useUpdateParty()
  const history = useHistory()

  const closeModal = () => {
    history.replace(history.location.pathname)
  }

  const onSubmit = async ({ location }) => {
    const { party } = await mutation.mutateAsync({ id, fields: { location } })
    if (party) {
      toast.success('Location Updated')
      closeModal()
    }
  }

  return (
    <Modal onCancel={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 style={{ paddingBottom: 5 }}>Choose a Location</h2>
        <p>Ready to decide on a location?</p>
        <LocationInput
          style={{ margin: '20px 0 30px 0' }}
          control={control}
          name="location"
          placeholder="Eg. Alemany Farmer's Market on 100 Alemany Blvd, San Francisco"
          required
        />
        <Button disabled={!formState.isValid || mutation.isLoading}>
          Done
        </Button>
      </form>
    </Modal>
  )
}

LocationModal.propTypes = { id: PropTypes.string.isRequired }

export default LocationModal
