/**
 * @file Cancel Party Modal
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/simple/Modal'
import Button from 'components/simple/Button'
import { useHistory } from 'react-router-dom'
import useUpdateParty from 'hooks/query/party/useUpdateParty'

const CancelModal = ({ id }) => {
  const history = useHistory()
  const mutation = useUpdateParty()

  const handleCancelParty = async () => {
    const { party } = await mutation.mutateAsync({
      id,
      fields: { status: 'CANCELLED' },
    })
    if (party.status === 'CANCELLED') history.replace(history.location.pathname)
  }

  const handleKeepParty = () => {
    history.replace(history.location.pathname)
  }

  return (
    <Modal>
      <h2 style={{ paddingBottom: 5 }}>Are you sure?</h2>
      <p>Aww party pooper, are you sure you’d like to cancel this party?</p>
      <Button
        style={{ marginTop: 30, marginBottom: 10 }}
        onClick={handleCancelParty}
      >
        Yes, Cancel It
      </Button>
      <Button type="secondary" onClick={handleKeepParty}>
        No, Keep It
      </Button>
    </Modal>
  )
}

CancelModal.propTypes = {
  id: PropTypes.string.isRequired,
}

export default CancelModal
