/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import PulseWrapper from './PulseWrapper'

const Container = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 225px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`

const Background = styled.div`
  background-color: #e4e6ee;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  z-index: -1;
  opacity: 0.5;
`

const TitlePlaceholder = styled.div`
  background-color: #e4e6ee;
  width: 150px;
  max-width: 90%;
  border-radius: 10px;
  height: 33px;
`

const TimePlaceholder = styled.div`
  background-color: #e4e6ee;
  width: 75px;
  max-width: 70%;
  border-radius: 10px;
  height: 23px;
  margin-top: 5px;
`

const HostContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  display: flex;
  align-items: center;
`

const HostPicture = styled.div`
  background-color: #e4e6ee;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 5px;
`

const HostName = styled.div`
  background-color: #e4e6ee;
  width: 70px;
  height: 18px;
  border-radius: 10px;
`

const PartyCardLoading = () => (
  <PulseWrapper>
    <Container>
      <Background />
      <TitlePlaceholder />
      <TimePlaceholder />
      <HostContainer>
        <HostPicture />
        <HostName />
      </HostContainer>
    </Container>
  </PulseWrapper>
)

export default PartyCardLoading
