/**
 * @file Current user's profile screen
 * @author Alwyn Tan
 */

import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Page from 'components/templates/Page'
import ProfileInfoCard from 'components/compound/profile/ProfileInfoCard'
import { useParams } from 'react-router-dom'
import useGetUserInfo from 'hooks/query/user/useGetUserInfo'
import ProfilePageLoading from 'components/compound/loading/ProfilePageLoading'
import SEO from 'components/simple/SEO'
import EditProfileModal from 'components/compound/profile/EditProfileModal'
import { AnimatePresence } from 'framer-motion'
import RootContext from 'RootContext'

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;
  padding-top: 35px;
`

const SmallButton = styled.h4`
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 17px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Accent};
  margin-top: 10px;
`

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  > h2 {
    padding-top: 20px;
  }
`

const ProfilePicture = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const Profile = () => {
  const { id } = useParams()
  const [showEdit, setShowEdit] = useState(false)
  const { auth } = useContext(RootContext)
  const { isLoading, data } = useGetUserInfo(id)
  const user = data?.user || {}
  const isCurrentUser = id === auth?.user?.id

  const renderQuestionCards = () =>
    Object.keys(user.questions || {}).map(key => (
      <ProfileInfoCard key={key} title={key} content={user.questions[key]} />
    ))

  const handleEditProfileClick = () => {
    setShowEdit(true)
  }

  if (isLoading) return <ProfilePageLoading />

  return (
    <Page>
      <SEO title={user.name} image={user.picture} />
      <Container>
        <InfoContainer>
          <ProfilePicture src={user.picture} />
          <h2>{user.name}</h2>
          {isCurrentUser && (
            <SmallButton onClick={handleEditProfileClick}>
              Edit Profile
            </SmallButton>
          )}
        </InfoContainer>
        <ProfileInfoCard title="I work in" content={user.profession} />
        <ProfileInfoCard title="Did you know" content={user.funFact} />
        {renderQuestionCards()}
        <div style={{ minHeight: 60 }} />
      </Container>
      <AnimatePresence>
        {isCurrentUser && showEdit && (
          <EditProfileModal onCancel={() => setShowEdit(false)} />
        )}
      </AnimatePresence>
    </Page>
  )
}

export default Profile
