/**
 * @file Input for 4 digit code
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import { useController } from 'react-hook-form'

const Container = styled.div`
  display: flex;
  justify-content: center;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const Pin = styled(OtpInput)`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 50px;
  width: 50px;
  margin: 0 5px;
  display: flex;
  justify-content: center;

  > * {
    height: 100%;
    width: 100% !important;
    text-align: center;
    outline: none;
    border: none;
    border-radius: inherit;

    // copies the h4 styles
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
  }
`

const numInputs = 4

const CodeInput = ({ control, name, required }) => {
  const { field } = useController({
    name,
    control,
    rules: { required },
  })

  return (
    <Container>
      <Pin
        numInputs={numInputs}
        isInputNum
        {...field}
        onChange={code => field.onChange(code)}
      />
    </Container>
  )
}

CodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  required: PropTypes.bool,
  validate: PropTypes.func,
}

CodeInput.defaultProps = {
  required: false,
  validate: null,
}

export default CodeInput
