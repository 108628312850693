/**
 * @file Close Party Onboarding Overlay
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Button from 'components/simple/Button'

const Container = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Info = styled.div`
  background-color: white;
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 300px;
  align-self: flex-end;

  > h3 {
    padding-bottom: 10px;
  }
`

const HostButtons = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: space-around;

  > *:first-child {
    margin-right: 5px;
  }

  > *:last-child {
    margin-left: 5px;
  }
`

const ClosePartyOverlay = () => (
  <Container>
    <Info>
      <h3>Close The Party</h3>
      <p>
        When you’re ready to close the party with the current party people,
        click this here.
        <br />
        <br /> We’ll draft a group chat for you to send updates real-time to
        everyone coming. After you close the party, any late-comers will have to
        text you directly
      </p>
    </Info>
    <HostButtons>
      <div style={{ width: '100%' }} />
      <Button>Close It</Button>
    </HostButtons>
  </Container>
)

export default ClosePartyOverlay
