/**
 * @file Time Onboarding Overlay
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import TimeInfo from 'components/compound/party/details/TimeInfo'

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 190px;
`

const Info = styled.div`
  background-color: white;
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 300px;
  align-self: flex-start;

  > h3 {
    padding-bottom: 10px;
  }
`

const PartyInfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  > *:first-child {
    margin-right: 5px;
  }

  > *:last-child {
    margin-left: 5px;
  }
`

const TimeOverlay = () => (
  <Container>
    <Info>
      <h3>Change the Time</h3>
      <p>Need to update the time? Click here to do so.</p>
    </Info>
    <PartyInfoContainer>
      <TimeInfo isOnboarding isActive isHost />
      <div style={{ width: '100%', padding: 20 }} />
    </PartyInfoContainer>
  </Container>
)

export default TimeOverlay
