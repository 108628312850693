/**
 * @file Fat Buttons for party frontend, slightly different from our core Solo buttons
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 15px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, type }) =>
    type === 'primary' ? 'transparent' : theme.Accent};
  background-color: ${({ theme, type }) =>
    type === 'primary' ? theme.Accent : theme.Primary};
  color: ${({ theme, type }) => (type === 'primary' ? 'white' : theme.Accent)};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const Button = ({ type, children, onClick, disabled, style }) => (
  <Container onClick={onClick} disabled={disabled} style={style} type={type}>
    <h3>{children}</h3>
  </Container>
)

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

Button.defaultProps = {
  type: 'primary',
  children: 'Button',
  onClick: () => {},
  disabled: false,
  style: {},
}

export default Button
