/**
 * @file App Routes
 * @author Alwyn Tan
 */

import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import Home from 'pages/Home'
import Auth from 'pages/Auth'
import CreateParty from 'pages/CreateParty'
import ProtectedRoute from 'components/simple/ProtectedRoute'
import Party from 'pages/Party'
import Profile from 'pages/Profile'
import Menu from 'components/compound/Menu'
import FourOhFour from 'pages/FourOhFour'
import useSilentLogin from 'hooks/query/auth/useSilentLogin'
import { Loader } from 'react-feather'
import styled, { createGlobalStyle } from 'styled-components'
import YourParties from 'pages/YourParties'
import RootContext from 'RootContext'

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.Primary}
  }
`

const App = () => {
  const { setHideHeader } = useContext(RootContext)
  const { isLoading } = useSilentLogin()
  const history = useHistory()
  const shouldHideHeader = new URLSearchParams(useLocation().search).get(
    'hideHeader'
  )

  useEffect(() => {
    let prevPathname = null

    if (shouldHideHeader) setHideHeader(true)

    return history.listen(location => {
      if (location.pathname !== prevPathname) prevPathname = location.pathname
      window.analytics.page()
    })
  }, [history, shouldHideHeader, setHideHeader])

  return (
    <>
      <GlobalStyle />
      {isLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <Auth />
      <Menu />
      {!isLoading && (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/404" component={FourOhFour} />
          <ProtectedRoute exact path="/create" component={CreateParty} />
          <ProtectedRoute exact path="/parties" component={YourParties} />
          <Route exact path="/user/:id" component={Profile} />
          <Route exact path="/:id" component={Party} />
        </Switch>
      )}
    </>
  )
}

export default App
