/**
 * @file Header Dropdown Component for when clicking on the profile button
 * @author Alwyn Tan
 */

import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const Container = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  border-radius: 10px;
  position: absolute;
  top: 30px;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  min-width: 150px;
`

const DropdownSection = styled.div`
  padding: 8px 40px 8px 16px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.Tertiary};
  }
`

const HeaderDropdown = ({ onHide }) => {
  const history = useHistory()
  const { auth } = useContext(RootContext)
  const containerRef = useRef(null)

  useEffect(() => {
    const handleClick = e => {
      if (containerRef.current && !containerRef.current.contains(e.target))
        onHide()
    }
    window.document.addEventListener('click', handleClick)

    return () => window.document.removeEventListener('click', handleClick)
  }, [onHide])

  const handleLogout = async () => {
    await get('/api/auth/logout', { includeCredentials: true })
    window.location.reload()
  }

  if (!auth.user) return null

  return (
    <Container ref={containerRef}>
      <DropdownSection
        onClick={() => history.push(`/user/${auth.user.id}`)}
        style={{ borderRadius: '10px 10px 0 0', paddingTop: 12 }}
      >
        <h4>{auth.user.name}</h4>
        <h5 style={{ opacity: 0.5 }}>{auth.user.phoneNumber}</h5>
      </DropdownSection>
      <DropdownSection onClick={() => history.push(`/user/${auth.user.id}`)}>
        <h4>Profile</h4>
      </DropdownSection>
      <DropdownSection
        onClick={handleLogout}
        style={{ borderRadius: '0 0 10px 10px', paddingBottom: 12 }}
      >
        <h4>Sign Out</h4>
      </DropdownSection>
    </Container>
  )
}

HeaderDropdown.propTypes = {
  onHide: PropTypes.func.isRequired,
}

export default HeaderDropdown
