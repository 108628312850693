/**
 * @file Root Context wrapper that houses global states (auth & menu)
 * @author Alwyn Tan
 */

import React from 'react'

// set the defaults
const RootContext = React.createContext({
  auth: {},
  setAuth: () => {},
  authSuccessAction: null,
  setAuthSuccessAction: () => {},
  showAuth: false,
  setShowAuth: () => {},
  showMenu: false,
  setShowMenu: () => {},
  hideHeader: false,
  setHideHeader: () => {},
})

export default RootContext
