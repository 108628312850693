/**
 * @file A simple implementation of an infinite scroll element
 * @author Alwyn Tan
 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div``

const InfiniteScroll = ({ children, loadingComponent, infiniteQueryHook }) => {
  const infiniteTriggerRef = useRef(null)

  const { fetchNextPage, data, hasNextPage, isFetchingNextPage, isLoading } =
    infiniteQueryHook()

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [data?.pages, hasNextPage, fetchNextPage])

  return (
    <Container>
      {(data?.pages || []).map(page => page.data.map(item => children(item)))}
      {(isLoading || isFetchingNextPage) && loadingComponent}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

InfiniteScroll.propTypes = {
  children: PropTypes.func.isRequired,
  loadingComponent: PropTypes.node,
  infiniteQueryHook: PropTypes.func.isRequired,
}

InfiniteScroll.defaultProps = {
  loadingComponent: <p>Loading</p>,
}

export default InfiniteScroll
