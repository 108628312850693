/* eslint-disable import/prefer-default-export */

/**
 * @file Auth related constants
 * @author Alwyn Tan
 */

export const AUTH_SUCCESS_ACTION = {
  JOIN_PARTY: 'JOIN_PARTY',
}
