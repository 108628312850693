/**
 * @file Modal to close the party
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/simple/Modal'
import { useHistory } from 'react-router-dom'
import useUpdateParty from 'hooks/query/party/useUpdateParty'
import Button from 'components/simple/Button'
import useGetParty from 'hooks/query/party/useGetParty'
import { PARTY_CLOSE_MESSAGE } from 'constants/party'

const CloseModal = ({ id }) => {
  const history = useHistory()
  const { data } = useGetParty(id)
  const mutation = useUpdateParty()

  const party = data?.party

  const handleCloseParty = async () => {
    window.open(
      `sms:/open?addresses=${party.participants.map(
        p => p.phoneNumber
      )}&body=${PARTY_CLOSE_MESSAGE}`
    )

    const response = await mutation.mutateAsync({
      id,
      fields: { status: 'CLOSED' },
    })
    if (response.party.status === 'CLOSED')
      history.replace(history.location.pathname)
  }

  const handleKeepParty = () => {
    history.replace(history.location.pathname)
  }

  if (!party) return null

  return (
    <Modal onCancel={handleKeepParty}>
      {!party.location || !party.startDate ? (
        <>
          <h2 style={{ paddingBottom: 5 }}>One Sec!</h2>
          <p>
            You’ll need to add a time and location before you can close your
            party!
          </p>
          <Button style={{ marginTop: 30 }} onClick={handleKeepParty}>
            Back
          </Button>
        </>
      ) : (
        <>
          <h2 style={{ paddingBottom: 5 }}>Are you sure?</h2>
          <p>
            Are you sure you want to close the party? Your party’s guest list
            will be finalized and newcomers will have to message you if they
            want to join.
          </p>
          <Button
            style={{ marginTop: 30, marginBottom: 10 }}
            onClick={handleCloseParty}
          >
            Yes, Close It
          </Button>
          <Button type="secondary" onClick={handleKeepParty}>
            No, Not Yet
          </Button>
        </>
      )}
    </Modal>
  )
}

CloseModal.propTypes = { id: PropTypes.string.isRequired }

export default CloseModal
