/**
 * @file Controls which party modals to show (forces only 1 type of modal). Relies on history state so that other routes can control it
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { PARTY_MODAL } from 'constants/party'
import StartedModal from './modals/StartedModal'
import CancelModal from './modals/CancelModal'
import TimeModal from './modals/TimeModal'
import LocationModal from './modals/LocationModal'
import JoinClosedPartyModal from './modals/JoinClosedPartyModal'
import CloseModal from './modals/CloseModal'
import JoinedModal from './modals/JoinedModal'
import ParticipantsModal from './modals/ParticipantsModal'

const ModalController = ({ id }) => {
  const location = useLocation()

  return (
    <AnimatePresence>
      {location.state?.modal === PARTY_MODAL.STARTED && <StartedModal />}
      {location.state?.modal === PARTY_MODAL.JOINED && <JoinedModal />}
      {location.state?.modal === PARTY_MODAL.CANCEL && <CancelModal id={id} />}
      {location.state?.modal === PARTY_MODAL.CLOSE && <CloseModal id={id} />}
      {location.state?.modal === PARTY_MODAL.TIME && <TimeModal id={id} />}
      {location.state?.modal === PARTY_MODAL.PARTICIPANTS && (
        <ParticipantsModal id={id} />
      )}
      {location.state?.modal === PARTY_MODAL.LOCATION && (
        <LocationModal id={id} />
      )}
      {location.state?.modal === PARTY_MODAL.JOIN_CLOSED && (
        <JoinClosedPartyModal id={id} />
      )}
    </AnimatePresence>
  )
}

ModalController.propTypes = {
  id: PropTypes.string.isRequired,
}

export default ModalController
