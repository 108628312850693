/**
 * @file Gets a party query
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_PARTY_URL = '/api/party'

const getParty = async (id, accessToken) =>
  get(`${GET_PARTY_URL}/${id}`, { accessToken })

const useGetParty = id => {
  const { auth } = useContext(RootContext)
  return useQuery(
    ['party', id, auth?.user?.id],
    async () => getParty(id, auth.accessToken),
    { refetchOnWindowFocus: false }
  )
}

export default useGetParty
