/**
 * @file Time Info
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Edit2 } from 'react-feather'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { PARTY_MODAL } from 'constants/party'

const Container = styled.div`
  background-color: #01021d;
  height: 120px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  cursor: pointer; // todo: if user is not host?
  position: relative;

  :first-child {
    margin-right: 5px;
  }

  :last-child {
    margin-left: 5px;
  }

  > h5 {
    opacity: 0.5;
    color: white;
  }

  > div {
    color: white;
    height: 60px;
    display: flex;
    align-items: center;

    > h3 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`

const EditButton = styled(Edit2)`
  top: 12px;
  right: 12px;
  position: absolute;
`

const TimeInfo = ({ isOnboarding, isActive, isHost, date }) => {
  const history = useHistory()

  const handleClick = () => {
    if (isHost && isActive && !isOnboarding)
      history.push({ state: { modal: PARTY_MODAL.TIME } })
  }

  const getFormattedStartDate = () => {
    if (!date) return null
    const d = dayjs(date)

    const formattedTime = d.format('hh:mma')

    let formattedDay = ''
    if (d.isToday()) formattedDay = 'Today'
    else if (d.isTomorrow()) formattedDay = 'Tomorrow'
    else formattedDay = d.format('MMM DD')

    return `${formattedDay}, ${formattedTime}`
  }

  return (
    <Container onClick={handleClick}>
      {isHost && isActive && <EditButton color="white" size={15} />}
      <h5>When</h5>
      <div>
        <h3>{getFormattedStartDate() || 'TBD'}</h3>
      </div>
    </Container>
  )
}

TimeInfo.propTypes = {
  isOnboarding: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isHost: PropTypes.bool.isRequired,
  date: PropTypes.string,
}

TimeInfo.defaultProps = {
  isOnboarding: false,
  date: null,
}

export default TimeInfo
