/**
 * @file Gets a user's profile query
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_USER_INFO_URL = '/api/user/info'

const getUserInfo = async (id, accessToken) =>
  get(`${GET_USER_INFO_URL}?id=${encodeURIComponent(id)}`, { accessToken })

const useGetUserInfo = id => {
  const { auth } = useContext(RootContext)
  return useQuery(['user', id], async () => getUserInfo(id, auth.accessToken), {
    refetchOnWindowFocus: false,
  })
}

export default useGetUserInfo
