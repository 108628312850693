/**
 * @file Action Sheet Modal Component (Slides up from bottom)
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Background = styled(motion.div)`
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 400px;
`

const Container = styled(motion.div)`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Primary};
  padding: 30px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s ease height;
  max-height: 100%;
  overflow: auto;
  position: relative;
`

const ActionBar = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.Accent};
  padding: 20px 0;
  position: sticky;
  z-index: 10;
  width: 100%;
  top: 0;
  background-color: ${({ theme }) => theme.Primary};

  > div {
    display: flex;
    width: 100%;
    max-width: 400px;
  }
`

const BackButton = styled.h4`
  margin-right: auto;
  cursor: pointer;
`

const CancelButton = styled.h4`
  margin-left: auto;
  cursor: pointer;
`

const Modal = ({ children, onBack, onCancel }) => {
  const showActionBar = onBack || onCancel

  return (
    <Background
      initial={{
        backdropFilter: 'blur(0px) brightness(100%)',
        WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      }}
      animate={{
        backdropFilter: 'blur(3px) brightness(50%)',
        WebkitBackdropFilter: 'blur(3px) brightness(50%)',
      }}
      exit={{
        backdropFilter: 'blur(0px) brightness(100%)',
        WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
      exitTransition={{ ease: 'easeOut' }}
      onClick={onCancel}
    >
      <Container
        initial={{ y: '150%' }}
        animate={{ y: 0 }}
        exit={{ y: '150%' }}
        transition={{ duration: 0.2 }}
        onClick={e => e.stopPropagation()}
        style={{ paddingTop: showActionBar ? 0 : 30 }}
      >
        {showActionBar && (
          <ActionBar>
            <div>
              {onBack && <BackButton onClick={onBack}>Back</BackButton>}
              {onCancel && (
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              )}
            </div>
          </ActionBar>
        )}
        <MaxWidthContainer>{children}</MaxWidthContainer>
      </Container>
    </Background>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
}

Modal.defaultProps = {
  onBack: null,
  onCancel: null,
}

export default Modal
