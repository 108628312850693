/**
 * @file Menu Page
 * @author Alwyn Tan
 */

import React, { useContext } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { X } from 'react-feather'
import { Link } from 'react-router-dom'
import RootContext from 'RootContext'

const FullScreenContainer = styled(motion.div)`
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.Accent};
  display: flex;
  justify-content: center;
  padding: 20px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
  width: 100%;
  max-width: 400px;
  position: relative;
`

const StyledLink = styled(Link)`
  padding: 15px;
  color: white;
  text-decoration: none;
`

const XButton = styled(X)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`

const LoggedInStatus = styled.div`
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 100%;
`

const Name = styled(Link)`
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
`

const Menu = () => {
  const { auth, showMenu, setShowMenu } = useContext(RootContext)

  return (
    <AnimatePresence>
      {showMenu && (
        <FullScreenContainer
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ ease: 'anticipate' }}
        >
          <Container>
            <XButton size={22} onClick={() => setShowMenu(false)} />
            {auth?.user?.id && (
              <StyledLink
                to={`/user/${auth?.user?.id}`}
                onClick={() => setShowMenu(false)}
              >
                <h2>Your Profile</h2>
              </StyledLink>
            )}
            <StyledLink to="/parties" onClick={() => setShowMenu(false)}>
              <h2>View Your Parties</h2>
            </StyledLink>
            <StyledLink to="/create" onClick={() => setShowMenu(false)}>
              <h2>Start a Party 🎉</h2>
            </StyledLink>
            {auth?.user?.name && (
              <LoggedInStatus>
                <h3>
                  <span style={{ opacity: 0.5 }}>Logged in as </span>
                  <Name
                    to={`/user/${auth?.user?.id}`}
                    onClick={() => setShowMenu(false)}
                  >
                    <span>{auth?.user?.name}</span>
                  </Name>
                </h3>
              </LoggedInStatus>
            )}
          </Container>
        </FullScreenContainer>
      )}
    </AnimatePresence>
  )
}

export default Menu
